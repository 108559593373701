<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <div class="card card-body item">
          <a href="https://tahdir.net/admin" target="_blank">
            ادمن التحضير القديم
          </a>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="card card-body item">
          <a href="https://admin.naqar.net" target="_blank">
            ادمن نقار
          </a>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="card card-body item">
          <a href="https://console-devices.tahdir.net" target="_blank">
            ادمن اجهزة البصمة
          </a>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="card card-body item">
          <a href="https://adminbus.tahdir.net" target="_blank">
            ادمن التوصيل المدرسي
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 text-center g" v-if="!loaded">
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" v-model="sms" value="yes">
          تضمين الsms
        </label>
      </div>
      <button class="btn btn-primary btn-lg" @click="gett(); loaded = true">
        <i class="fa fa-list"></i>
        عرض الاحصائيات
      </button>
      <br>
      <br>
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" @change="changestatus()" v-model="slow_mode" value="true">
          الوضع البطيئ
        </label>
      </div>
    </div>
    <div class="row" v-if="loaded">
      <div class="col-12 col-lg-2 c" v-for="s in stats.cards" :key="s.title">
        <div class="card card-body text-center">
            <h1>
              {{ s.count }}
            </h1>
            {{ s.title }}
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card card-body">
          <div class="table-responsive col-12">
            <p>
            احصائيات الداتا بيز
          </p>
          <table class="table table-hover table-bordered table-sm">
            <thead>
              <th>
                اسم الجدول
              </th>
              <th>
                عدد السجلات
              </th>
            </thead>
            <tbody>
              <tr v-for="table in stats.tables" :key="table.title">
                <td>
                  {{ table.title.replace('.js', '') }}
                </td>
                <td>
                  {{ table.count }}
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <div class="card card-body">
          <div class="table-responsive col-12">
            <p>
            احصائيات الخدمات
          </p>
          <table class="table table-hover table-bordered table-sm">
            <thead>
              <th>
                الخدمة
              </th>
              <th>
                فعالة تجربة
              </th>
              <th>
                فعالة شراء
              </th>
              <th>
                منتهية
               تجربة
              </th>
              <th>
                منتهية
                 شراء
              </th>
            </thead>
            <tbody>
              <tr v-for="service in stats.services" :key="service.title">
                <td>
                  {{ service.title }}
                </td>
                <td>
                  {{ service.active_demo }}
                </td>
                <td>
                  {{ service.active_pay }}
                </td>
                <td>
                  {{ service.end_demo }} <a href="javascript:;" @click="view(service.end_demo_users)"><i class="fa fa-eye"></i></a>
                </td>
                <td>
                  {{ service.end_pay }} <a href="javascript:;" @click="view(service.end_pay_users)"><i class="fa fa-eye"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
        <div class="card card-body">
          <div class="table-responsive col-12">
            <p>
            احصائيات الرسائل
          </p>
          <table class="table table-hover table-bordered table-sm">
            <thead>
              <th>
                المزود
              </th>
              <th>
                العدد هذا الشهر
              </th>
            </thead>
            <tbody>
              <tr v-for="(a,b) in stats.sms" :key="a">
                <td>
                  {{ b }}
                </td>
                <td>
                  {{ a }}
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      jwt: localStorage.getItem("jwt"),
      slow_mode: false,
      stats: {
        cards: []
      },
      loaded: false,
      sms: false
    }
  },
  created(){
    var g = this;
      $.post(api + '/admin/slow_status', {
        jwt: this.jwt
      }).then(function(a){
        g.slow_mode = a.status
      })
  },
  methods: {
    view(arr){
      alert(`<textarea class='form-control' rows="10">${arr.join('\n')}</textarea>`)
    },
    changestatus(){
      var g = this;
      if(g.slow_mode){
        $.post(api + '/admin/slow_enable', {
          jwt: this.jwt
        }).then(function(a){
        })
      }else{
        $.post(api + '/admin/slow_stop', {
          jwt: this.jwt,
        }).then(function(a){
        })
      }
    },
    gett(){
      var g = this;
      $.post(api + '/admin/stats', {
        jwt: this.jwt,
        sms: this.sms
      }).then(function(a){
        g.stats = a.response
      })
    }
  }
}
</script>

<style>
.item a{
  height: 100%;
}
.item{
  text-align: center !important;
}
.item:hover{
  background: #eee;
  cursor: pointer;
}
</style>